<template>
  <div class="box">
    <div class="headline">功能概况</div>
    <div class="content">
      <p>1.运行程序，登录界面输入《用户名称》或《用户编号》，输入《密码》新用户默认“123456”,也可通过手机发送验证码进行登录。</p>
      <img src="../../assets/specificationImgs/img1.png" alt width="100%" />
      <p>2.注册账号</p>
      <img src="../../assets/specificationImgs/img2.png" alt="" width="50%">
      <p>点击如图注册按键，触发注册操作：</p>
      <img src="../../assets/specificationImgs/img3.png" alt="" width="50%">
      <p>如上图：输入用户名称、手机号、二次登录密码，然后点击发送验证码，约10秒可在手机上面收到验证码<img src="../../assets/specificationImgs/img10.png" alt="">将收到的验证码填入，并输入图形验证码，点击创建账号。</p>
      <p>注意：密码需要满足一定的密码强度，由字符、数字、特殊符号等组成不低于8位的字符串才可以。验证码可能因为网络有所延迟。
        接下来即可登录。</p>
        <p>3.登录以后进入主界面。</p>
        <img src="../../assets/specificationImgs/img4.png" alt="" width="100%">
        <p>初次登录时，尚未绑定公司信息，可以选中创建团队或者加入公司。
        点击“加入公司”时必须要知道公司的名称，如果需要测试数据，可以加入“广州市景翔信息科技有限公司”，可以触发一下操作。</p>
        <img src="../../assets/specificationImgs/img5.png" alt="">
        <p>4.加入之后如图：</p>
        <img src="../../assets/specificationImgs/img6.png" alt="">
        <p>组织所有者可以设置加入的方式，需要审批或者可以直接加入。</p>
        <p>5.初次登录时，也可以创建团队，首创者默认位团队的所有者。</p>
        <img src="../../assets/specificationImgs/img7.png" alt="" width="100%">
        <p>创建团队时，需要录入公司的基本信息，点击确定时，默认会弹出演示数据导入提示：</p>
        <img src="../../assets/specificationImgs/img8.png" alt="">
        <p>这里点击确定时，会导入一个部门的数据，可以供我们测试整个系统的功能。注意的是，该提示只会弹出一次，取消后不可再次发起。</p>
        <p>这里我点击确定<img src="../../assets/specificationImgs/img9.png" alt="">需要稍等一段时间，约45秒左右。
        到此，登录与注册就完成了。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
